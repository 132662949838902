import { jsonLdScriptProps } from 'react-schemaorg';
import { ChevronRightIcon } from '@heroicons/react/outline';
import Link from '../helper/link';

export default function Breadcrumbs({ items = [] }) {
  const data = items;

  if (data[0]?.link !== '/') {
    data.unshift({
      title: 'CharterCheck',
      link: '/',
      target: '',
      active: 1,
      current: 0,
      spacer: 0,
      hasSubpages: 1,
    });
  }

  const processLink = (link) => {
    const l = link
      .replace(/^.*\/\/[^/]+/, '') // remove domain name
      .replace(/^\//, ''); // remove first slash

    return `/${l}`;
  };

  const itemListElement = data.map((i, k) => ({
    '@type': 'ListItem',
    position: k + 1,
    item: `${process.env.NEXT_PUBLIC_SITE_URL}${processLink(i.link)}`,
    name: i.title,
  }));

  return (
    <>
      <script
        {...jsonLdScriptProps(
          {
            '@context': 'https://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement,
          },
        )}
      />

      <div id="breadcrumbs" className="mb-4">
        <ul className="flex gap-x-2 text-sm text-gray-600 flex-wrap">
          {data.map((item, k) => (
            <li className="flex items-center" key={item.title}>
              <Link
                href={processLink(item.link)}
                title={item.title}
                activeClassName="font-semibold"
              >
                <a>
                  <span>{item.title}</span>
                </a>
              </Link>
              {k + 1 < items.length ? <ChevronRightIcon className="h-4 mt-0.5 ml-2" /> : ''}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}
