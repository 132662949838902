import { useTranslation } from 'next-i18next';
import LicenseIcon from '@/assets/icons/anker/license.svg';
import ExternalIcon from '@/assets/icons/anker/external.svg';
import Link from 'next/link';

export function DetermineCharterLicense(boat, booking = null) {
  const dataSource = booking || boat;
  const isMultipleBasementsNoBooking = () => !booking && boat.basements.length > 1;
  const isWithoutLicense = () => !isMultipleBasementsNoBooking()
    && (dataSource.licenses_str === 'Charterbescheinigung'
      || dataSource.licenses_str === 'keine'
      || dataSource.licenses_str === 'Charterbescheinigung, keine'
    );
  return { isMultipleBasementsNoBooking: isMultipleBasementsNoBooking(), isWithoutLicense: isWithoutLicense() };
}

export function LicenseDescription({ boat, booking = null, detail = false }) {
  const { t } = useTranslation('boat');
  const dataSource = booking || boat;
  if (boat.basements.length > 1 && !booking) {
    return t('Requirements depending on region');
  }
  const { isMultipleBasementsNoBooking, isWithoutLicense } = DetermineCharterLicense(boat, booking);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {/* eslint-disable-next-line no-nested-ternary */}
      {isMultipleBasementsNoBooking
        ? (
          <>
            {t('Minimum requirements to run this ship')}
            :
            {' '}
            {t('Requirements depending on region')}
          </>
        )
        // eslint-disable-next-line no-nested-ternary
        : (isWithoutLicense
          ? (
            <>
              Für dieses Boot benötigen Sie
              {' '}
              <b className="">keinen</b>
              {' '}
              Bootsführerschein.
            </>
          )
          : (
            (detail ? (
            // eslint-disable-next-line react/jsx-no-useless-fragment
              <>
                {t('Minimum requirements to run this ship')}
                :
                {' '}
                {dataSource.licenses_str === '' ? t('maybe license') : dataSource.licenses_str}
              </>
            )
              : (
            // eslint-disable-next-line react/jsx-no-useless-fragment
                <>
                  {t('you_need_a_boat_license')}
                </>
              )
            )

          ))}
    </>
  );
}

export default function BoatCharterLicenseNotice({ boat, booking = null }) {
  const { isWithoutLicense } = DetermineCharterLicense(boat, booking);

  return (
    <div
      className={`flex flex-row items-center p-2 space-x-2 overflow-hidden text-white text-sm md:text-base ${isWithoutLicense ? 'bg-green-600' : 'bg-primary'}`}
    >
      <LicenseIcon width={30} fill="currentColor" />
      <span className="text-white">
        <LicenseDescription boat={boat} booking={booking} detail />
        <br />
        Weitere Informationen finden Sie im
        {' '}
        <Link href="https://skipper.adac.de/sportbootfuehrerscheine-funkzeugnisse-patente-pyroscheine/">
          <a target="_blank">
            <span className="underline" title="ADAC Skipperportal Führerscheininformationen">ADAC&nbsp;Skipperportal</span>
            &nbsp;
            <ExternalIcon width={12} height={12} fill="white" className="inline-block" />
          </a>
        </Link>
      </span>
    </div>
  );
}
